<template>
  <div
    class="notice-item-wrapper"
    :class="{ disabled: item.isRead }"
    @click="handleNotificationClick"
  >
    <div class="notice-item">
      <img class="notice-item__img" :src="logoUrl" alt="logo" />

      <div class="notice-item__content">
        <div class="notice-item__info">
          <div class="notice-item__text">
            <span class="notice-item__text-accent">{{ item.title }}</span>
          </div>
          <div
            v-if="item.isRead"
            class="notice-item__delete"
            @click.stop="deleteNotification(item._id)"
          >
            <svg class="icon ic-close" width="28" height="28">
              <use xlink:href="@/assets/sprites/sprite.svg#ic-close"></use>
            </svg>
          </div>
          <div v-else class="notice-item__circle">
            <div class="notice-item__circle-inner"></div>
          </div>
        </div>
        <div class="notice-item__action">
          <div class="notice-item__date">{{ formattedDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { INotification } from '@/declare/interfaces';
import { useRouter } from 'vue-router';
import { useUIStore } from '@/store/ui';
import dayjs from '@/common/lib/datetime';

const props = defineProps({
  item: {
    type: Object as () => INotification,
    required: true
  }
});
const emit = defineEmits(['update:isRead', 'delete']);

const router = useRouter();
const logoUrl = new URL('../../assets/img/logo.jpg', import.meta.url).href;

const leadView = computed(() => {
  if (useUIStore().leadView === 'cards') {
    return 'cards';
  } else {
    return 'leads';
  }
});

const formattedDate = computed(() => {
  return props.item.createdAt ? dayjs(props.item.createdAt).fromNow().replace(' ago', '') : '';
});

function toggleRead() {
  emit('update:isRead', props.item._id, props.item?.isRead);
}

function navigateToSource() {
  emit('update:isRead', props.item._id, props.item?.isRead);

  if (props.item.action === 'receiving_a_lead') {
    if (props.item.content.businessId && props.item.content.campaignId) {
      router.push(
        `/b/${props.item.content.businessId}/c/${props.item.content.campaignId}/${leadView.value}`
      );
    }
  }
}

async function deleteNotification(id: string) {
  emit('delete', id);
}

function handleNotificationClick() {
  if (props.item.action === 'receiving_a_lead') {
    navigateToSource();
  }
  toggleRead();
}
</script>

<style lang="scss">
@use '@/assets/styles/notice.scss';
</style>
