import { defineStore } from 'pinia';
import { Filter, SelectItem } from '@/declare/interfaces';
import router from '@/router/router';
import { Campaign_URL } from '@/common/lib/url';
import { get, post } from '@/common/lib/api';
import { CampaignBusiness, ICampaign } from '@/declare/types/campaign';
import { ICampaignUser } from '@/declare/types/user';

interface CampaignState {
  campaigns: ICampaign[];
  currentCampaign: ICampaign | null;
  selectedCampaignList: SelectItem[];
  actionCampaignList: ICampaign[];
  actionCampaign: ICampaign | null;
  campaignUsers: number;
  campaignBusinesses: number;
  businesses: CampaignBusiness[] | [];
  hasCreatedCampaign: boolean;
  showVirtualNumberModal: boolean;
  virtualNumberCreated: boolean;
}

export const useCampaignStore = defineStore({
  id: 'campaign-store',
  state: (): CampaignState => ({
    campaigns: [] as ICampaign[],
    currentCampaign: null as ICampaign | null,
    selectedCampaignList: [] as SelectItem[],
    actionCampaignList: [] as ICampaign[],
    actionCampaign: null as ICampaign | null,
    campaignUsers: 0,
    campaignBusinesses: 0,
    businesses: [] as CampaignBusiness[],
    hasCreatedCampaign: false,
    showVirtualNumberModal: false,
    virtualNumberCreated: false
  }),
  getters: {
    getCampaigns: state => state.campaigns,
    getCurrentCampaign: state => state.currentCampaign,
    getSelectedCampaignList: state => state.selectedCampaignList,
    getCampaignUsers: state => state.campaignUsers,
    getActionCampaignList: state => state.actionCampaignList,
    getActionCampaign: state => state.actionCampaign,
    getCampaignBusinesses: state => state.campaignBusinesses
  },
  actions: {
    async fetchCampaigns(page = 1, limit = 10, filters: Filter[] = [], isSingle = false) {
      if (isSingle) {
        const single = this.campaigns.find(campaign => campaign._id === filters[0].value);
        if (single) return { campaigns: [single] };
      }
      const params = new URLSearchParams({
        page: String(page),
        limit: String(limit),
        filters: encodeURIComponent(JSON.stringify(filters))
      });
      const [status, data] = await get(Campaign_URL, { params });

      if (status === 200 && data.success) {
        const campaigns = data.response.campaigns;
        this.populateCampaigns(campaigns);

        return { campaigns, pagination: data.response.pagination };
      }
    },

    async getCampaign(
      id: string
    ): Promise<{ users: ICampaignUser[]; campaign: ICampaign } | false> {
      try {
        const [success, response] = await get(Campaign_URL + 'info', {
          params: {
            campaignId: id
          }
        });

        if (!success) {
          return false;
        }
        return response as { users: CampaignUser[]; campaign: ICampaign };
      } catch (e) {
        console.error('Error fetching campaign data:', e);
        return false;
      }
    },

    async deleteCampaign(id: string): Promise<boolean> {
      try {
        const [status, data] = await post(Campaign_URL + `${id}/delete`);
        return status === 200 && data.success;
      } catch (error) {
        console.error('Error deleting campaign:', error);
        return false;
      }
    },

    populateCampaigns(campaigns: ICampaign[]) {
      this.setCampaigns(campaigns);
      this.setSelectedCampaignList(
        campaigns.map((campaign: ICampaign) => ({
          key: campaign._id,
          name: campaign.name,
          status: false
        }))
      );
    },
    reset() {
      this.$reset();
    },

    setCampaigns(campaigns: ICampaign[]) {
      this.$patch({ campaigns });
      this.$patch({ actionCampaignList: campaigns });
    },

    setCurrentCampaign(campaign: ICampaign | null) {
      if (campaign) {
        const url = router.currentRoute.value.path;
        const regex = /\/c\/[a-zA-Z0-9]+/;
        const newUrl = url.replace(regex, `/c/${campaign._id}`);
        if (url !== newUrl) {
          return router.push(newUrl);
        }
      }
      if (!campaign) {
        const storedCurrentCampaign = sessionStorage.getItem('currentCampaign');
        if (storedCurrentCampaign) {
          this.currentCampaign = JSON.parse(storedCurrentCampaign) as ICampaign;
          return;
        }
      }
      this.currentCampaign = campaign;
      this.actionCampaign = campaign;
      if (campaign) {
        //this.fetchCampaignUsers(campaign._id).catch(console.error);
        sessionStorage.setItem('currentCampaign', JSON.stringify(campaign));
      } else {
        this.campaignUsers = 0;
        sessionStorage.removeItem('currentCampaign');
      }
    },

    setSelectedCampaignList(newCampaigns: SelectItem[]) {
      this.selectedCampaignList = newCampaigns;
    },
    setHasCreatedCampaign(flag: boolean) {
      this.$patch({ hasCreatedCampaign: flag });
    },
    setShowVirtualNumberModal(flag: boolean) {
      this.$patch({ showVirtualNumberModal: flag });
    },
    setVirtualNumberCreated(flag: boolean) {
      this.$patch({ virtualNumberCreated: flag });
    }
  },
  persist: false
});

export default useCampaignStore;
